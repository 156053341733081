import * as React from "react";
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { GatsbySeo, BookJsonLd } from 'gatsby-plugin-next-seo';
import { Col, Container, Row, Button } from "react-bootstrap";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const BookLayout = ({ data }) => {
  const url = data.site.siteMetadata.siteUrl + data.graphCmsBook.slug;

  return (
    <Layout title={data.graphCmsBook.title}>
      <GatsbySeo
        openGraph={{
          title: data.graphCmsBook.title,
          description: data.graphCmsBook.shortDescription,
          url,
          type: 'book',
          book: {
            releaseDate: data.graphCmsBook.released,
            isbn: data.graphCmsBook.isbn,
            authors: [
              data.site.siteMetadata.siteUrl
            ],
            tags: data.graphCmsBook.tags,
          },
          images: [
            {
              url: data.graphCmsBook.coverImage.url,
              width: data.graphCmsBook.coverImage.width,
              height: data.graphCmsBook.coverImage.height
            },
          ],
        }}
      />
      <BookJsonLd
        author={{ name: data.site.siteMetadata.author }}
        url={url}
        name={data.graphCmsBook.title}
        workExample={data.graphCmsBook.editionsSeo || []}
      />

      <Container>
        <section>
          <Row>
            <Col xs={12} md={4} lg={3} className="text-center">
              <GatsbyImage image={getImage(data.graphCmsBook.coverImage)} alt={`Book Cover for ${data.graphCmsBook.title}`} className="mb-3"/>
            </Col>
            <Col xs={12} md={8} lg={9}>
              <h1>{data.graphCmsBook.title}</h1>
              <p className="lead">
                {data.graphCmsBook.shortDescription}
              </p>

              <div className="mb-3">
                {data.graphCmsBook.links.buy.map((link) => (
                  <Button key={link.link} variant="primary" href={link.link} className="me-2 mb-2">{link.text}</Button>
                ))}
              </div>

              <div dangerouslySetInnerHTML={{ __html: data.graphCmsBook.description.html }}/>
            </Col>
          </Row>
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    graphCmsBook(id: {eq: $id}) {
      title
      slug
      released
      isbn
      shortDescription
      description {
        html
      }
      shortDescription
      tags
      coverImage {
        gatsbyImageData(layout: CONSTRAINED, width: 300)
      }
      editionsSeo
      links
    }
    site {
      siteMetadata {
        siteUrl
        author
      }
    }
  }
`

export default BookLayout
